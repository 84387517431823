<template>
  <div class="page">
    <!-- <div class="empty-container">
      <van-empty image="https://directimg.miaocang.cc/mpImg/wx/empty/goods@2x.png" description="敬请期待" />
    </div> -->
    <div class="main-container">
      <img
      src="https://directimg.miaocang.cc/mpImg/wx/green_introduction@2x.png"
      draggable="false"
      oncontextmenu="return false;"
      class="unselectable"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GreenIntroduction'
}
</script>

<style lang="less" scoped>
.page {
  width: 100vw;
  background-color: #00ae66;
  .main-container {
    img {
      width: 100%;
    }
  }
  .empty-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
}
</style>
